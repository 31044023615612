@import './vars.scss';

body {
  font-family: 'Netto OT', sans-serif;
  font-weight: 400;
  max-width: 900px;
  margin: auto;
  background: #5d5d5d0d;
  width: 100%;
  overflow-x: hidden;
  touch-action: pan-x pan-y;
}

a {
  text-decoration: none !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: inherit;
}

a.ant-btn {
  padding-top: initial;
  line-height: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

// ...........................HOME.............................

// CMN STYLES

.cmnBtn {
  background-color: $themecolor;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  height: 45px;
  margin-top: 10px;
  min-width: 130px;
  padding: 0 10px;
  outline: none !important;
  box-shadow: none !important;
  @media (max-width: 767px) {
    height: 40px;
    min-width: 120px;
  }
}
.cmnBtnHouseKeeping {
  background-color: $themecolor;
  border-radius: 50px;
  border: none;
  color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  height: 30px;
  margin-top: 1px;
  min-width: 50px;
  padding: 0 10px;
  outline: none !important;
  box-shadow: none !important;
  @media (max-width: 767px) {
    height: 25px;
    min-width: 60px;
  }
}

.outerlineBtn {
  background-color: #ee183c00;
  border-radius: 100px;
  border: 1px solid #70707040;
  color: #ee183c;
  font-size: 15px;
  font-weight: 700;
  height: 45px;
  margin-top: 10px;
  min-width: 130px;
  padding: 0 10px;
  @media (max-width: 767px) {
    height: 40px;
    min-width: 120px;
  }
}

.cmnBtn2 {
  font-size: 15px;
  color: #ee183c;
  font-weight: 700;
  background-color: #ee183c00;
  height: 45px;
  border-radius: 100px;
  min-width: 130px;
  padding: 0 10px;
  margin-top: 10px;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  @media (max-width: 767px) {
    height: 40px;
    min-width: 120px;
  }
}

.iconBtn {
  background-color: $themecolor;
  border: none;
  border-radius: 100px;
  padding: 0 18px;
  height: 24px;
  display: flex;
  align-items: center;

  img {
    width: 13px;
  }
}

.iconBtnnobg {
  border: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 3px;
  top: 8px;
  background: none;
  z-index: 11;
}

.pt-66 {
  padding-top: 66px !important;
}

.cmninput {
  label {
    font-size: 15px;
    color: #a8a8a8;
  }

  input {
    color: #000000;
    border-radius: 5px;
    border: 1px solid #00000040;
    height: 50px;
    line-height: 50px;
    background-color: #ffffff40;

    &::-webkit-input-placeholder {
      color: #000000;
    }

    &:-ms-input-placeholder {
      color: #000000;
    }

    &::placeholder {
      color: #000000;
    }
  }
}

.cmnDatepicker {
  margin-bottom: 30px;

  label {
    font-size: 15px;
    color: #a8a8a8;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  input {
    color: #000000;
    border-radius: 5px;
    border: 1px solid #00000040;
    height: 50px;
    line-height: 50px;
    background-color: #ffffff40;
    width: 100%;
    padding: 0 15px;
    outline: none;
    background-repeat: no-repeat;
    background-position: 95% center;
    background-image: url('../assets/backgroundimages/calendar.png');

    &::-webkit-input-placeholder {
      color: #000000;
    }

    &:-ms-input-placeholder {
      color: #000000;
    }

    &::placeholder {
      color: #000000;
    }
  }

  .react-datepicker-popper {
    z-index: 1111;
  }
}

.customSelect-wrp {
  .customSelect__menu {
    z-index: 11111;
  }

  .customSelect__control {
    min-height: 50px;
    background-color: #f7f7f7;
    border-radius: 5px;
    border: 1px solid #00000040;
  }

  .customSelect__option {
    font-size: 15px;
    color: #000000;
    padding: 12px 12px;
  }

  .customSelect__option--is-selected {
    color: #fff;
    background-color: $themecolor;
  }

  .customSelect__indicator-separator {
    display: none;
  }

  .customSelect__placeholder {
    color: #000000;
  }

  input {
    height: auto;
    line-height: initial;
  }
}

.cmntempselect {
  margin-bottom: 30px;

  .customSelect__control {
    min-height: 50px;
    background-color: #ffffff38;
  }
}

.cmnTimepicker {
  margin-bottom: 30px;
}

.disabledate {
  opacity: 0.3;
  background-color: #a5a5a5 !important;
}

.selectdisable {
  opacity: 0.3;
  background-color: #a5a5a5 !important;
  border-radius: 5px;
  overflow: hidden;

  .customSelect__control {
    background-color: #a5a5a500 !important;
  }

  .customSelect__indicator {
    color: #6e6e6e;
  }

  // .customSelect__placeholder {
  //   color: #00000061;
  // }
  // .customSelect__control {
  //   cursor: no-drop;
  // }
}

.container-wrp {
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}

.form-row {
  margin-right: -8px;
  margin-left: -8px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 8px;
  padding-left: 8px;
}

// CMN STYLES

.header-wrp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  z-index: 111;
  width: 100%;
}

.logo-wrp {
  text-align: center;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 23px;
    color: #ee183c;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
    margin-left: 5px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
}
.home-header {
  .logo-wrp {
    padding-left: 38px;
  }
}
.left-aligned {
  justify-content: flex-start;
  padding-left: 0 !important;
}
.logo-img {
  margin-right: 5px;
  img {
    max-width: 100px;
    max-height: 60px;
  }
}
.backBtn {
  background: none;
  border: none;
  padding: 0;
  position: relative;
  top: -2px;
}

.menubtn {
  position: relative;

  span {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #ee183c;
    border-radius: 50%;
    right: -5px;
  }
}

.homebanner-wrp {
  position: relative;

  .bannerImg {
    img {
      height: 170px;
      width: 100%;
      object-fit: cover;
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-image: url('../assets/backgroundimages/bannerlayer.png');
  }
}

.usercard-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
  padding: 10px 25px 10px 25px;

  .userimg {
    flex: none;
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  h6 {
    font-size: 21px;
    color: #ffffff;
    font-weight: 300;
  }

  h2 {
    font-size: 29px;
    color: #ffffff;
    font-weight: 700;
    word-break: break-all;
  }
}

.footer-wrp {
  height: 65px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 9px 0;
  z-index: 11;

  ul {
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0 3px 17px #00000052;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    position: relative;
    top: -37px;
  }
}

.openpage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 69vh;
  width: 50vw;
  margin: auto;
  background: #fff;
}

.menufooter {
  bottom: 30px;
  position: absolute;
}

.username-wrp {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  .userimg {
    flex: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 15px;
  }

  h2 {
    font-size: 25px;
    color: #ffffff;
    font-weight: 700;
    word-break: break-all;
  }

  h6 {
    font-size: 11px;
    color: #ffffffab;
  }

  .UserAvatar--inner {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #fff;
    background: #ffffff4f !important;
    color: #fff;
    letter-spacing: 1px;
  }
}

.appin {
  transition: all 0.3s;
  margin: auto;
  transform: initial;
}

.sideMenu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100vw;
  right: 0;
  background: #ee183c;
  z-index: 111;
  padding: 35px 20px;
  transition: left 0.3s;
  max-width: 900px;

  ul {
    margin-top: 70px;

    @media (max-width: 480px) {
      max-width: 165px;
    }

    li {
      font-size: 13px;
      color: #ffffff;
      margin: 25px 0;

      a {
        display: flex;
        align-items: center;
        color: #ffffff;
      }

      img {
        margin-right: 15px;
        width: 13px;
      }
    }
  }
}

.menuOpen {
  overflow: hidden;

  .App {
    min-width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .appin {
    z-index: 1111;
    background: #fff;
    border-radius: 20px;
    transition: all 0.3s;
    box-shadow: -9px 4px 20px 4px #0000002b;
    transform: scale(0.62) translate(50%, 0vh);
    position: absolute;
    overflow: hidden;
    width: 100vw;
    max-width: 1024px;
    height: 108vh;
    bottom: 0;
    top: 0;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    @media (max-width: 575px) {
      transform: scale(0.62) translate(60%, 0vh);
    }
  }

  .sideMenu {
    left: 0vw;
    transition: left 0.3s;
    margin: auto;
  }
}

.menuclosebtn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
}

.contentarea {
  padding: 25px 0 110px 0;
}

.homeContent {
  .departmentCard {
    h5 {
      font-size: 15px;
    }
  }
}

.departmentCard {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 138px;
  margin-bottom: 16px;
  max-height: 208px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h5 {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    color: #ffffff;
    font-weight: 700;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 10px;
    word-break: break-word;
  }
}

.h-95 {
  .departmentCard {
    height: 95px;
  }
}

.h-110 {
  .departmentCard {
    height: 110px;
  }
}

.h-119 {
  .departmentCard {
    height: 119px;
  }
}

.h-175 {
  .departmentCard {
    height: 175px;
  }
}

.department-wrp {
  .leftPart {
    .departmentCard {
      height: 208px;
    }
  }

  .rightPart {
    .departmentCard {
      height: 96px;
    }
  }
}

.popularservice-wrp {
  .popularserviceHead {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 25px 0;
    align-items: center;

    h4 {
      font-size: 24px;
      color: #000000;
      font-weight: 700;

      @media (max-width: 401px) {
        font-size: 22px;
      }
    }

    h6 {
      font-size: 12px;
      color: #c2bebe;
    }

    .checkinbtn {
      font-size: 10px;
      width: 90px;
      height: 34px;
      margin-bottom: 0;
      margin-right: 0;

      img {
        width: 13px;
      }
    }
  }

  .departmentCard {
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #0000002e;
    }
  }
}

.serviceCard {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 138px;
  margin-bottom: 16px;
  max-height: 208px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h5 {
    position: absolute;
    z-index: 1;
    font-size: 15px;
    color: #ffffff;
    font-weight: 700;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 10px;
  }
}

.servicepromotionCard {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  min-height: 110px;
  margin-bottom: 16px;
  max-height: 208px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #d200238f;
  }

  h5 {
    font-size: 10px;
    color: #000000;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }

  h4 {
    font-size: 17px;
    color: #ffffff;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }

  h6 {
    font-size: 10px;
    color: #ffffff;
    position: relative;
    z-index: 1;
  }
}

// ...........................HOME.............................

// ...........................LANDING PAGE.............................

.landingContent {
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.landingpage-wrp {
  height: 100vh;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0000007d;
  }
}

.landingLogo {
  margin-bottom: 170px;
  max-width: 120px;
}

.landingBottom {
  text-align: center;
  // position: absolute;
  // bottom: 50px;
}

.checkinbtn {
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
  background-color: $themecolor;
  border: none;
  min-width: 130px;
  padding: 0 20px;
  height: 50px;
  border-radius: 100px;
  display: block;
  margin: 0 auto 50px;
  outline: none !important;
  box-shadow: none !important;

  img {
    margin-left: 10px;
  }
}

.outerimgbanner {
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 35px;
}

// ...........................LANDING PAGE.............................

// ...........................QR PAGE.............................
.qrWrp {
  padding: 80px 30px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
  h6 {
    font-size: 26px;
    color: #ee183c;
    margin-top: 50px;
    padding: 0 10px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  h3 {
    color: white;
  }
}

.qrData {
  figure {
    margin: auto;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
  }
  h5 {
    font-size: 14px;
    color: #9e9e9e;
  }
  h4 {
    font-size: 18px;
    margin: 15px 0 5px;
  }
}
.qrBtn-wrp {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin: 0 auto;
}

.image-upload > label {
  background-color: $themecolor;
  padding: 6px 10px;
  margin: 0;
  border-radius: 50px;
  width: 120px;
  color: #fff;
  & > img {
    height: 40px;
  }
}

.image-upload > input {
  display: none;
}

.backgroundColor {
  background-color: $themecolor;
}

.qrvideoBox {
  width: 250px;
  height: 250px;
  margin: auto;
  position: relative;
  video {
    width: 250px;
    height: 250px;
    object-fit: fill;
    border-radius: 15px;
  }
  .scan-region-highlight {
    width: 90% !important;
    height: 90% !important;
    top: 5% !important;
    left: 5% !important;
  }
}

// ...........................QR PAGE.............................

// ...........................LANGUAGE SELECT.............................

.Lnaguagepick-wrp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 66px);
  padding-bottom: 100px;
}

.languageSelect-wrp {
  margin-bottom: 20px;

  .languageSelect__menu {
    z-index: 1111;
  }

  .languageSelect__control {
    min-height: 50px;
  }

  .languageSelect__option {
    font-size: 15px;
    color: #000000;
    padding: 12px 12px;
  }

  .languageSelect__option--is-selected {
    color: #fff;
    background-color: $themecolor !important;
  }

  .languageSelect__indicator-separator {
    display: none;
  }
}

// ...........................LANGUAGE SELECT.............................

// ...........................BOOKING REFERENCE.............................

.bookingReferance-wrp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 131px);
  padding-bottom: 100px;
}

// ...........................BOOKING REFERENCE.............................
// ...........................MY PROFILE.............................

.myProfile-wrp {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: calc(100vh - 66px);
  padding-bottom: 120px;
}

.profilefig-wrp {
  text-align: center;
  margin-top: 10px;

  h5 {
    font-size: 15px;
    color: #000000;
    margin: 10px 0 25px;
  }
}

// ...........................MY PROFILE.............................

// ...........................TEMPLATES.............................

.templatecontentArea {
  min-height: calc(100vh - 236px);
  display: flex;
  padding: 25px 0 120px 0;
  flex-direction: column;
  justify-content: space-between;
}

// ...........................TEMPLATES.............................

// ...........................SUCCESS ..............................

.success-wrp {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: calc(100vh - 131px);
  padding: 50px 50px 120px 50px;
  text-align: center;

  h5 {
    font-size: 14px;
    color: #a8a8a8;
    margin: 30px 0 50px 0;
    line-height: 19px;
  }
}
.confirmation-wrp {
  padding: 50px 30px 120px 30px;
  min-height: initial;
  justify-content: space-between;
}
.gobacklink {
  color: blue;
  padding: 7px 0;
  text-align: center;
  position: fixed;
  bottom: 64px;
  left: 0;
  right: 0;
  background: #f6f6f6;
}
// ...........................SUCCESS .............................

// ...........................FRONT DESK.............................

.cmnHeader {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 25px 0;
  align-items: center;

  h4 {
    font-size: 24px;
    color: #000000;
    font-weight: 700;

    @media (max-width: 401px) {
      font-size: 22px;
    }
  }

  h6 {
    font-size: 12px;
    color: #c2bebe;
  }

  .checkinbtn {
    font-size: 10px;
    width: 90px;
    height: 34px;
    margin-bottom: 0;
    margin-right: 0;

    img {
      width: 13px;
    }
  }
}

.departmentBanner-wrp {
  position: relative;

  .bannerImg {
    img {
      height: 170px;
      width: 100%;
      object-fit: cover;
    }
  }

  h1 {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    position: absolute;
    bottom: 15px;
    left: 25px;
  }
}

// .........Doctor on a call ......
.doconcall-wrp {
  min-height: calc(100vh - 236px);
  display: flex;
  padding: 50px 0 120px 0;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  h5 {
    font-size: 14px;
    color: #a8a8a8;
    margin: 20px 0 10px 0;
    line-height: 19px;
  }
}

// .........Doctor on a call ......

// .........VIEW BILL ......

.billCard-wrp {
  margin-bottom: 20px;

  h5 {
    font-size: 13px;
    color: #a8a8a8;
    margin-top: 15px;
  }
}

.billCard {
  background: rgb(11, 172, 118);
  background: linear-gradient(
    90deg,
    rgba(11, 172, 118, 1) 0%,
    rgba(15, 216, 189, 1) 100%
  );
  border-radius: 10px;
  padding: 15px;

  h6 {
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 29px;
    color: #ffffff;
  }
}

// .........VIEW BILL ......

// ...........................FRONT DESK.............................

// ...........................FOOD AND BEVERAGE.............................
// .........ROOM SERVICE MENU ......
.roomserviceMenu-wrp {
  padding: 25px 0 110px 0;
}

.foodtypes-wrp {
  overflow-x: auto;
  border-bottom: 1px solid #70707026;
  padding-bottom: 15px;

  .menutypelist {
    width: 100%;
    white-space: nowrap;
  }
}

.menutype {
  text-align: center;
  font-size: 12px;
  color: #000000;
  width: 100px;
  padding: 0 5px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
  figure {
    display: flex;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto auto 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  span {
    cursor: pointer;
    word-break: break-all;
    white-space: break-spaces;
  }
}

.menuitemCard {
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 15px;

  figure {
    width: 122px;
    height: 90px;
    border-radius: 5px;
    flex: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .menuitemDetails {
    padding: 10px 0 5px 10px;
    width: 100%;
    position: relative;

    h5 {
      font-size: 15px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    h6 {
      font-size: 21px;
      color: #ee183c;
      margin-top: 15px;
    }
  }
}
//HouseKeeping Memu Card
.menuitemCardHouseKeeping {
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 15px;

  figure {
    width: 122px;
    height: 90px;
    border-radius: 5px;
    flex: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .menuitemDetails {
    padding: 10px 0 5px 10px;
    width: 100%;
    position: relative;

    h5 {
      font-size: 15px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    h6 {
      font-size: 21px;
      color: #ee183c;
      margin-top: 15px;
    }
  }
}
//end
.itemDescription {
  p {
    font-size: 13px;
    margin-bottom: 5px;
  }
  span {
    color: #2196f3;
    font-size: 13px;
    display: block;
    cursor: pointer;
  }
  &.trimmed {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.addCount {
  border: 1px solid #70707040;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  padding: 1px 6px;
  margin-left: auto;

  button {
    background: none;
    border: none;
    font-size: 16px;
    line-height: 18px;
    padding: 0;
  }

  input {
    font-size: 12px;
    color: #000000;
    font-weight: 700;
    text-align: center;
    border: none;
    background: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.continuestrip {
  background-color: #ee183c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-radius: 3px;
  padding: 16px 15px;

  h6 {
    font-size: 12px;
    color: #ffffff;
    font-weight: 700;
  }

  button {
    color: #fff;
    font-size: 12px;
    background: none;
    border: none;
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
    }
  }
}
.continuestrip2 {
  background-color: #ee183c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-radius: 3px;
  padding: 16px 150px;

  h6 {
    font-size: 12px;
    color: #ffffff;
    font-weight: 700;
  }

  button {
    color: #fff;
    font-size: 12px;
    background: none;
    border: none;
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
    }
  }
}

// .........ROOM SERVICE MENU ......

// .........ROOM SERVICE CART ......

.cartitems-wrp {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.cmntitleimage {
  text-align: center;
  margin: 20px 0 10px 0;

  h4 {
    font-size: 15px;
    color: #000000;
    margin: 10px 0;
  }
}

.cart-wrp {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: calc(100vh - 66px);
  padding-bottom: 120px;
}

.cartitemCard {
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px 15px;
  margin-bottom: 15px;

  figure {
    width: 100px;
    height: auto;
    border-radius: 5px;
    margin-right: 10px;
    flex: none;
  }

  h6 {
    font-size: 12px;
    color: #000000;
    font-weight: 700;
    flex: none;
    margin-right: 10px;
    @media (max-width: 767px) {
      font-size: 11px;
      margin-top: 2px;
    }
  }

  h5 {
    font-size: 13px;
    color: #000000;
    font-weight: 700;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 18px;
    color: #ee183c;
    white-space: nowrap;
    margin-left: auto;
    float: right;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  div {
    flex-basis: 100%;
    -webkit-flex-basis: 100%;
  }
}

.totalshow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 20px 15px;

  h4 {
    font-size: 25px;
    color: #000000;
    font-weight: 700;
  }

  h6 {
    font-size: 23px;
    color: #ee183c;
    display: flex;
    align-items: center;
  }
}

// .........ROOM SERVICE CART ......

// .........CUISINE LIST ......

.cuisineCard {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px 15px;
  margin-bottom: 15px;
  cursor: pointer;

  figure {
    max-width: 82px;
    border-radius: 5px;
    margin-right: 8px;
  }

  .cuisinedetails {
    flex: 1;
  }

  h3 {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
    margin: 2px 0 13px;
  }

  h6 {
    font-size: 12px;
    color: #00000063;
    margin-top: 4px;
  }

  .cuisinedescription {
    width: 100%;

    p {
      font-size: 12px;
      color: #000000;
      margin: 15px 0;
    }
  }

  .cmnBtn,
  .cmnBtn2 {
    font-size: 11px;
    height: 27px;
    min-width: 75px;
  }
}

// .........CUISINE LIST ......

// .........RESTAURANT LIST ......

.restaurantCard {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 12px 12px;
  margin-bottom: 15px;
  align-items: center;

  figure {
    width: 66px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }

  h3 {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
    margin: 2px 0 10px;
    cursor: pointer;
  }

  h6 {
    font-size: 12px;
    color: #00000063;
  }

  .cmnBtn,
  .cmnBtn2 {
    font-size: 11px;
    height: 27px;
    min-width: 75px;
    margin-top: 15px;
  }
}

.lightbox-wrp {
  .react_lightgallery_item {
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
      object-fit: cover;
    }
  }
}

// .........RESTAURANT LIST ......

// .........RESERVE ......

.reserveRestaurantCard {
  background-color: #ee183c;
  display: flex;
  padding: 20px 25px;
  align-items: center;

  figure {
    max-width: 66px;
    border-radius: 5px;
    margin-right: 20px;
  }

  h3 {
    font-size: 21px;
    color: #fff;
    font-weight: 700;
    margin: 2px 0 5px;
  }

  h6 {
    font-size: 12px;
    color: #fff;
    margin-top: 5px;
  }
}

// .........RESERVE ......

// ...........................FOOD AND BEVERAGE.............................

// ...........................HOTEL INFO.............................
.hotelInfo-wrp {
  min-height: calc(100vh - 66px);
  padding-bottom: 100px;

  p {
    font-size: 13px;
    color: #000000;
  }
}

.hotelimages-wrp {
  margin: 20px 0 30px 0;

  figure {
    text-align: center;
    height: 83px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.map-wrp {
  width: 100%;
  height: 115px;
}

.HotelLogo {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 35px;
}

// ...........................HOTEL INFO.............................

// ...........................TERMS AND CONDITIONS.............................

.termsconditions-wrp {
  min-height: calc(100vh - 66px);
  padding-bottom: 100px;

  .outerimgbanner {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 15px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    font-size: 13px;
    color: #000000;
    margin-bottom: 20px;
  }
}

// ...........................TERMS AND CONDITIONS.............................

// ...........................PROMOTIONS.............................
.promotions-wrp {
  min-height: calc(100vh - 236px);
  display: flex;
  padding: 25px 0 120px 0;
  flex-direction: column;
  justify-content: space-between;
}
.houseKeepingProduct-wrp {
  min-height: calc(100vh - 236px);
  display: flex;
  padding: 25px 0 120px 0;
  flex-direction: column;
}

.promotionsCard {
  position: relative;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  margin-bottom: 15px;
  cursor: pointer;

  .promodetails {
    position: relative;
    width: 50%;
    margin-left: auto;
    padding: 13px 75px;
    min-height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px) {
      padding: 13px 40px;
    }

    @media (max-width: 575px) {
      padding: 13px 15px 13px 36px;
    }

    @media (max-width: 440px) {
      padding: 13px 10px 13px 22px;
    }

    @media (max-width: 340px) {
      width: 60%;
    }

    h6 {
      font-size: 15px;
      color: #000000;
      position: relative;
      z-index: 1;
      margin-bottom: 8px;
    }

    h4 {
      position: relative;
      color: #ee183c;
      font-size: 13px;
      z-index: 1;

      span {
        font-size: 28px;
        font-weight: 700;
        margin: 0 2px;
      }
    }

    .promocardbg {
      position: absolute;
      bottom: 0;
      right: -2px;
      top: -1px;
      height: 102%;
      border-radius: 10px;
    }
  }

  &:nth-of-type(even) {
    .promocardbg {
      path {
        fill: #ee183c;
      }
    }

    h4 {
      color: #fff;
    }

    .promodetails {
      margin-left: 0;
    }

    .promodetails .promocardbg {
      bottom: 0;
      right: 0;
      top: -1px;
      height: 102%;
      transform: rotateY(180deg);
      left: -1px;
    }
  }
}

.promotionsdetails-wrp {
  text-align: left;

  h4 {
    position: relative;
    color: #ee183c;
    font-size: 11px;
    z-index: 1;
    margin: 20px 0;

    span {
      font-size: 28px;
      font-weight: 700;
      margin: 0 2px;
    }
  }

  h5 {
    font-size: 15px;
    color: #a8a8a8;
    margin-bottom: 15px;
  }

  p {
    font-size: 13px;
    color: #000000;
  }
}

.promoimg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// ...........................PROMOTIONS.............................

// ...........................SPA AND WELLNESS.............................

.spawellnessDetails-wrp {
  min-height: calc(100vh - 236px);
  display: flex;
  padding: 25px 0 120px 0;
  flex-direction: column;
  justify-content: space-between;

  figure {
    margin-bottom: 25px;
  }

  p {
    font-size: 13px;
    color: #000000;
    margin-bottom: 15px;
  }
}

// ...........................SPA AND WELLNESS.............................

// ...........................NOTIFICATIONS.............................

.CmnModal-wrp {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000000c7;

  display: none;
  align-items: center;
  justify-content: center;

  &.open {
    display: flex;
    z-index: 11111;
  }

  .modalContent {
    background-color: #fff;
    width: 515px;
    max-width: 90%;
    text-align: center;
    padding: 20px;
    border-radius: 10px;

    p {
      font-size: 14px;
      color: #a8a8a8;
      margin: 15px 0;
    }
  }

  .outerlineBtn,
  .cmnBtn {
    min-width: 110px;
    font-size: 12px;
  }
}

.notificationbanner {
  text-align: center;
  border-bottom: 1px solid #70707040;
  padding: 20px;

  h5 {
    font-size: 20px;
    color: #000000;
    margin-top: 15px;
  }
}

.notificationcard {
  display: flex;
  align-items: flex-start;
  padding: 25px 25px;
  border-bottom: 1px solid #70707040;

  img {
    margin-right: 22px;
    filter: brightness(0) invert(0);
    -webkit-filter: brightness(0) invert(0);
  }

  h5 {
    font-size: 10px;
    color: #00000080;
    margin-bottom: 5px;
  }

  p {
    font-size: 13px;
    color: #000000;
  }

  &.newnotification {
    img {
      filter: brightness(1) invert(0);
      -webkit-filter: brightness(1) invert(0);
    }
  }
}

// ...........................NOTIFICATIONS.............................

// ...........................EVENTS.............................

.eventCard {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 12px 12px;
  margin-bottom: 15px;

  figure {
    width: 66px;
    border-radius: 5px;
    margin-right: 15px;
  }

  h3 {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
    margin: 5px 0 0px;
  }

  h4 {
    font-size: 14px;
    color: #ee183c;
    margin: 5px 0 20px;
  }

  h5 {
    font-size: 12px;
    color: #00000063;
    display: flex;
    align-items: center;
    margin-top: 8px;

    img {
      margin-right: 8px;
      margin-top: -2px;
    }
  }
}

// ...........................EVENTS.............................
// ...........................HOTEL GUIDELINES.............................

.hotelGuidelines {
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee2e;
  overflow: hidden;
  .guidelinesHaed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0px 15px;
    h4 {
      font-size: 18px;
      color: #aca8ac;
    }
  }
  .guidelineBox {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eeeeee;
    .react_lightgallery_item {
      display: flex;
      align-items: center;
    }
    figure {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex: none;
      margin-right: 8px;
      overflow: hidden;
    }
    h5 {
      font-size: 16px;
      color: #1780cd;
      margin-bottom: 3px;
      cursor: pointer;
    }
    h6 {
      font-size: 14px;
      color: #aca8ac;
    }
    button {
      margin-left: auto;
      background: #f7f7f7;
      border: none;
      color: #aca8ac;
      display: flex;
      align-items: center;
      justify-content: center;
      .anticon {
        line-height: 8px;
      }
    }
  }
}

.pdf-wrp {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
  .pdfAction-wrp {
    position: absolute;
    bottom: 13px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    padding: 0 25px;

    ul {
      display: flex;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    &:disabled {
      background-color: #b5b5b5;
      cursor: not-allowed;
    }
  }
  .cmnBtn {
    padding: 5px 15px;
    height: 100%;
    min-width: 70px;
    min-height: 30px;
    font-size: 12px;
    margin-top: 0;
  }
  .zoombtns {
    min-width: auto;
    padding: 0;
    width: 35px;
    height: 35px;
  }
}

.pdfModal {
  .modalBody {
    width: 100%;
    height: 100%;
  }
  &.CmnModal-wrp .modalContent {
    height: 99%;
    width: 850px;
    background-color: #fff;
    max-width: 99%;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    overflow: hidden;
  }
}
.closeBtn {
  display: flex;
  margin-left: auto;
  background: none;
  border: none;
  position: relative;
  right: -17px;
  top: -10px;
}

// ...........................HOTEL GUIDELINES.............................

// ...........................MAINTENANCE.............................

.maintenance-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px;
  text-align: center;
  figure {
    margin-bottom: 20px;
  }
  h3 {
    font-size: 14px;
    color: #a8a8a8;
  }
}
// ...........................MAINTENANCE.............................

// arabic styles
.urduLang,
.arabicLang {
  .sideMenu {
    right: -100vw;
    left: initial;

    ul {
      direction: rtl;
      max-width: 100% !important;

      li img {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  &.menuOpen {
    .appin {
      transform: scale(0.62) translate(-50%, 0vh);

      @media (max-width: 575px) {
        transform: scale(0.62) translate(-60%, 0vh);
      }
    }

    .sideMenu {
      right: 0;
      transition: right 0.3s;
    }
  }

  .departmentCard {
    direction: rtl;
    text-align: right;
  }

  .popularservice-wrp .popularserviceHead {
    direction: rtl;

    .checkinbtn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }

  .cmnHeader {
    direction: rtl;

    .checkinbtn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }

  .serviceCard {
    direction: rtl;
    text-align: right;
  }

  .departmentBanner-wrp h1 {
    left: auto;
    right: 25px;
  }

  .cmntempselect {
    direction: rtl;
  }

  .customSelect-wrp {
    direction: rtl;

    .customSelect__menu {
      text-align: right;
    }
  }

  .cmninput label {
    width: 100%;
    text-align: right;
  }

  .cmninput input {
    direction: rtl;
  }

  .cmnDatepicker {
    direction: rtl;

    input {
      background-position: 3% center;
    }

    label {
      width: 100%;
      text-align: right;
    }
  }

  .orderpersonDetails {
    text-align: right;
  }

  .menufooter {
    right: 15px;
  }
}

// arabic styles
// malayalam styles
.malayalamLang {
  @media (max-width: 575px) {
    .homeContent .departmentCard h5 {
      font-size: 11px;
    }

    .departmentCard h5 {
      padding: 5px;
      font-size: 11px;
    }

    .serviceCard h5 {
      padding: 5px;
      font-size: 11px;
    }

    .popularservice-wrp .popularserviceHead h4 {
      font-size: 20px;
    }

    .departmentBanner-wrp h1 {
      font-size: 21px;
    }

    .cmnHeader h4 {
      font-size: 20px;
    }
  }
}

// malayalam styles

// RESPONSIVE
// @media (max-width: 1430px) {
// }
// @media (max-width: 1199px) {
// }

// @media (max-width: 991px) {
// }
// @media (max-width: 767px) {
// }

@media (max-width: 575px) {
  .captcha-wrp {
    transform: scale(0.8);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
}

.disabled {
  // cursor: not-allowed !important;
  // pointer-events: none !important;
  opacity: 0.4;
  cursor: no-drop !important;

  a {
    cursor: no-drop !important;
  }
}

.disabledBackbutton {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 1111111;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: white;
  display: flex;
  align-content: center;
  justify-content: center;
}

.content-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.newClass {
  visibility: hidden;
}

.boxList {
  li {
    font-size: 14px;
    color: #0f0f0f;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background: #fff;
    padding: 12px 15px;
    border-radius: 6px;
    font-weight: 700;
    border: 1px solid #dbdbdb;

    &.active {
      color: #fff;
      background: #ee183c;
    }
  }
}

.orderpersonDetails {
  padding: 10px 15px;

  h6 {
    font-size: 14px;
    color: #a8a8a8;
  }
}

.disableClickDept {
  pointer-events: none !important;
}

#comingSoon {
  opacity: 1 !important;
  position: absolute;
  top: 40% !important;
  color: #ee183c !important;
  left: 50%;
  font-size: 25px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-weight: bold;
}

.rc-virtual-list .rc-virtual-list-scrollbar {
  display: block !important;
}

.lg-download {
  display: none;
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background: #19222fe8;
  display: flex;
  align-items: center;
  justify-content: center;

  &.content {
    position: inherit !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    .loaderWrapper {
      .loaderRail {
        background: #0000001f;
        &:after {
          background: red;
        }
      }
    }
  }

  .loaderWrapper {
    width: 115px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 60px;
    }
    .loaderRail {
      background: #ffffff47;
      width: 100%;
      height: 5px;
      border-radius: 50px;
      position: relative;
      overflow: hidden;
      &:after {
        position: absolute;
        content: '';
        top: 0;
        height: 100%;
        width: 50px;
        background: #ee183c;
        border-radius: 50px;
        animation: swing 3s ease-in-out infinite;
      }

      @keyframes swing {
        0% {
          left: -10px;
        }
        50% {
          left: calc(100% - 40px);
        }
        100% {
          left: -10px;
        }
      }
    }
  }
}

.thankyou-wrp {
  min-height: 100vh;
  padding: 50px 20px 50px;
}
.productionToast-msg {
  position: fixed;
  z-index: 999;
  width: 95%;
  height: auto;
  padding: 20px 50px 20px 20px;
  border-radius: 5px;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  background-color: #ee183c;
  text-align: center;
  max-width: 830px;
}
button.closeicon {
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}
.more-listing {
  button {
    font-size: 13px;
    color: #000000;
    font-weight: 700;
    border: 0.2px solid #ee183c;
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    padding: 14px 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:nth-child(even) {
      background: #ee183c;
      color: #fff;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.more {
  display: none;
}

.emoji {
  font-size: 22px;
  filter: brightness(160%) grayscale(100%) drop-shadow(0px 0.5px 1px gray);
  cursor: pointer;
  margin: 0 6px;
}

.selectedEmoji {
  filter: drop-shadow(0px 0.5px 1px gray);
}

button:disabled {
  opacity: 0.4;
  cursor: no-drop !important;
}
.feedbackModal {
  h4 {
    font-size: 20px;
    margin-bottom: 28px;
    font-weight: 700;
  }
  h5 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin-top: 18px;
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
  }
  textarea {
    font-size: 14px;
    padding: 10px;
  }
  .outerlineBtn {
    height: 40px;
  }
  .cmnBtn {
    height: 40px;
  }
}
.servicer-rating {
  .cmnBtn {
    height: 28px;
    min-width: 78px;
    font-size: 13px;
  }
}
.status-img {
  img {
    max-width: 50px;
    margin: 7px 0px 0px 0px;
  }
}
.responseModal {
  h6 {
    color: black;
    font-size: 18px;
    margin: 20px 0;
  }
  .outerlineBtn,
  .cmnBtn {
    min-width: 95px;
    height: 40px;
  }
}
.overall-feedback {
  padding-top: 25px;
  h4 {
    font-size: 22px;
    margin: 35px 0 20px 0;
  }
  h5 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .emoji {
    font-size: 18px;
  }
  .emoji-rating {
    margin-bottom: 20px;
  }
  textarea {
    width: 100%;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
  }
}

.grecaptcha-badge {
  margin-bottom: 52px;
}

//-----------------------------------chat.js-----------------------------------
// Variables
$primary-color: #ee183c;
$background-color: #fff;
$user-message-color: #ff6d6d;
$assistant-message-color: #f0f0f0;
$border-color: #d9d9d9;
$border-radius: 20px;

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-modal {
  position: fixed;
  bottom: 5vh;
  right: 5vw;
  width: 90vw;
  max-width: 380px;
  height: 80vh;
  max-height: 660px;
  background-color: $background-color;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (min-width: 768px) {
    bottom: 80px;
    right: 20px;
    width: 380px;
    height: 660px;
  }

  &.closed {
    display: none;
  }
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  scroll-behavior: instant;
}

.message-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.user-message,
.assistant-message {
  padding: 0.5rem 1rem;
  border-radius: $border-radius;
  margin-bottom: 0.5rem;
  max-width: 100%;
  word-break: break-word;
}

.user-message {
  background-color: $user-message-color;
  color: $background-color;
}

.assistant-message {
  background-color: $assistant-message-color;
  padding-left: 3rem;
  position: relative;
}

.iva-logo {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.input-container {
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  display: flex;
}

.input-field {
  flex: 1;
  margin-right: 10px;
  border-radius: $border-radius;
  border: 1px solid $border-color;
}

.send-button {
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  background-color: $primary-color;
  color: $background-color;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.custom-modal .ant-modal-content {
  border-radius: 8px;
}

.custom-modal .ant-modal-footer {
  border-top: none;
}

.custom-modal .ant-modal-body {
  padding-bottom: 0;
}

.custom-modal .modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
  border-top: none;
  padding: 10px 16px;
}

.custom-modal .custom-button {
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 14px;
}

.custom-modal .call-button.ant-btn {
  background-color: $primary-color;
  color: #fff;
  border: none;
}

.custom-modal .call-button.ant-btn:hover,
.custom-modal .call-button.ant-btn:focus {
  background-color: darken($primary-color, 10%);
  color: #fff;
}

.custom-modal .cancel-button.ant-btn {
  border: 2px solid #f0f0f0;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.85);
}

.custom-modal .cancel-button.ant-btn:hover,
.custom-modal .cancel-button.ant-btn:focus {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
}

.chat-layout {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.voice-mode-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}

.iva-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 15px;
  transition: all 0.3s ease;

  &.speaking {
    animation: ivaSpeak 1.5s infinite;
  }
}

.assistant-message-text {
  font-size: 16px;
  text-align: center;
}

.voice-recorder-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
}

.record-button {
  background-color: white !important;
  color: #1890ff !important;
  border: 2px solid #1890ff !important;
  width: 60px !important;
  height: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  line-height: 1 !important;
  font-size: 24px !important; // Adjust this if needed for the icon size

  &::after {
    display: none !important; // Remove any pseudo-elements that might affect the shape
  }

  &:hover,
  &:focus {
    background-color: white !important;
    color: #40a9ff !important;
    border-color: #40a9ff !important;
  }

  &.recording {
    background-color: #1890ff !important;
    color: white !important;
    animation: pulse 2s infinite;

    &:hover,
    &:focus {
      background-color: #40a9ff !important;
      color: white !important;
    }
  }

  .anticon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(24, 144, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0);
  }
}

@keyframes ivaSpeak {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
}

// ------------------------preChecking.scss----------------------

.pre-check-in-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensures the content covers at least the full viewport height
  text-align: center;
}

.pre-check-in {
  flex: 1; // Allows the content to grow and take available space
  max-width: 800px;
  margin: 0 auto;
  padding: 105px 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  text-align: left;

  h1 {
    color: #170f49;
    font-family: 'Netto Variable DEMO', sans-serif;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
    color: #909090;
    margin-bottom: 20px;
  }

  .form-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 34px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    .success-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 50px 20px;
      background-color: #fff;
      border-radius: 34px;

      h2 {
        font-size: 24px;
        font-weight: 700;
        color: #170f49;
        margin-top: 20px;
        line-height: 35px;
      }

      p {
        font-size: 18px;

        color: #6f6c90;
        margin-top: 10px;
        line-height: 30px;
      }

      .success-icon {
        width: 100px;
        height: 100px;
        background-color: #f0f0f0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
    }

    .precheckin-progress-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 30px;
      position: relative;

      .step {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        font-weight: bold;
        font-size: 14px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 40px; // This is fixed positioning of where it starts; you can tweak it if needed.
          width: 240px; // Use calc() to determine width dynamically while considering padding or other elements.
          max-width: 250px; // Set a maximum limit to prevent it from getting too wide.
          height: 4px;
          background-color: #e0e0e0;
          z-index: 0;
          transform: translateY(-50%);
          border-radius: 35px;

          @media (max-width: 768px) {
            width: calc(
              100% + 185px
            ); // Width reduced based on new starting point
          }

          @media (max-width: 480px) {
            width: calc(100% + 35px); // Width adjusted again to fit well
          }
        }

        &.active {
          background-color: #ff395a;
          color: #fff;
        }

        &.active::after {
          background-color: #ff395a;
        }

        &:last-child::after {
          display: none;
        }
      }
    }

    h2 {
      color: #333;
      margin-bottom: 10px;
      font-size: 28px;
      font-weight: 700;
      line-height: 35px;
    }

    p {
      color: #666;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }

    .form-row {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }

    .form-group {
      flex: 1;
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 5px;
        color: #333;
        font-weight: bold;
      }

      input[type='text'],
      input[type='email'],
      input[type='tel'],
      input[type='date'],
      input[type='time'],
      input[type='guestName'],
      input[type='bookingReferance'] {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 35px;
        font-size: 16px;
        background-color: #f8f8f8;

        &.error {
          border-color: #ff395a; // Red border for error state
          background-color: #fff5f5; // Light red background
        }
      }
      textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 35px;
        font-size: 16px;
        background-color: #f8f8f8;

        &.error {
          border-color: #ff395a;
          background-color: #fff5f5;
        }
      }

      .file-upload {
        .upload-button {
          display: block;
          width: 100%;
          padding: 12px;
          background-color: #fff;
          border: 1px solid #ff395a;
          border-radius: 25px;
          cursor: pointer;
          color: #ff395a;
          text-align: center;
          font-size: 16px;
        }
      }
      .error-message {
        color: #ff395a; // Red color for error messages
        font-size: 14px;
        margin-top: 5px;
        font-weight: 500;
        padding-left: 10px;
      }
    }

    .next-step {
      background-color: #522d90;
      color: #fff;
      border: none;
      padding: 12px 30px;
      border-radius: 50px;
      cursor: pointer;
      font-size: 16px;
      float: right;
      margin-top: 80px;
    }

    .prev-step {
      background-color: #fff;
      color: #ff395a;
      border: 1px solid #ff395a;
      border-radius: 50px;
      padding: 12px 30px;
      cursor: pointer;
      font-size: 16px;
      float: left;
      margin-top: 80px;
    }

    .submit {
      background-color: #522d90;
      color: #fff;
      border: none;
      padding: 12px 30px;
      border-radius: 50px;
      cursor: pointer;
      font-size: 16px;
      margin-top: 80px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .final-step {
      color: #170f49;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .precheckin-footer {
    width: 100%;
    background-color: #f8f8f8;
    text-align: center; // Center text content
    padding: 15px 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    justify-content: center; // Center the content horizontally
    align-items: center; // Vertically center the footer content
    position: relative; // Vertically center the footer content

    img {
      margin-left: 10px;
      width: 90px;
      height: 26px;
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .pre-check-in {
    padding: 20px;
  }

  h1 {
    font-size: 24px;
  }

  .subtitle {
    font-size: 14px;
  }

  .form-container {
    padding: 20px;
    .precheckin-progress-bar {
      .step {
        font-size: 12px;
        width: 25px;
        height: 25px;
      }
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }

    .form-row {
      flex-direction: column;
    }

    .next-step,
    .prev-step,
    .submit {
      font-size: 14px;
      padding: 10px 20px;
      margin-top: 20px;
    }
  }

  .precheckin-footer {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .pre-check-in {
    padding: 15px;
  }

  h1 {
    font-size: 20px;
  }

  .subtitle {
    font-size: 12px;
  }

  .form-container {
    padding: 15px;

    .precheckin-progress-bar {
      .step {
        font-size: 10px;
        width: 20px;
        height: 20px;
      }
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }

    .next-step,
    .prev-step,
    .submit {
      font-size: 12px;
      padding: 8px 16px;
      margin-top: 15px;
    }
  }

  .precheckin-footer {
    font-size: 14px;
  }
}

// -----------------------------UploadPopup.scss----------------------
.upload-popup {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.popup-content {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  width: 100%;
  max-width: 40rem;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.popup-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

.popup-header button {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.popup-header button:hover {
  color: #333;
}

.popup-body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.identity-id {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.identity-id input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.verify-button {
  align-self: flex-start;
  background-color: #522d90;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.verify-button:hover {
  background-color: #4a2785;
}

.response-message {
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.response-message.success {
  background-color: #e7f6e7;
  color: #28a745;
}

.response-message.error {
  background-color: #fbeaea;
  color: #dc3545;
}

.verification-success {
  color: #28a745;
  font-weight: 600;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 1rem;
}

.popup-footer button {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border: none;
  transition: background-color 0.3s ease;
}

.cancel-button {
  background-color: #f8f9fa;
  color: #6c757d;
}

.cancel-button:hover {
  background-color: #e2e6ea;
}

.upload-button {
  background-color: #28a745;
  color: white;
}

.upload-button:hover {
  background-color: #218838;
}

.upload-button:disabled {
  background-color: #94d3a2;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .popup-content {
    padding: 1rem;
    max-width: 30rem;
  }

  .popup-header h2 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .popup-content {
    padding: 0.5rem;
    max-width: 20rem;
  }

  .popup-header h2 {
    font-size: 0.875rem;
  }

  .popup-body input {
    font-size: 0.875rem;
  }

  .popup-footer button {
    font-size: 0.875rem;
  }
}

//---------------------------partnersManagement.scss--------------------------
.partners-management {
  .contentarea {
    .container-wrp {
      width: 100%;
      margin: 0 auto;

      .form-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, auto);
        grid-template-areas:
          'grid1 grid2'
          'grid1 grid3'
          'grid4 grid4'
          'grid5 grid5'
          'grid6 grid6';
        gap: 4px; // Ensure consistent gap between all grid items

        .col-12 {
          &:nth-child(1) {
            grid-area: grid1;
            .serviceCard {
              // Calculate height: 145px (E-Sim) + 145px (Airport) + 16px (gap) = 306px
              height: 90px !important;
              min-height: 280px !important;
            }
          }
          &:nth-child(2) {
            grid-area: grid2;
            .serviceCard {
              height: 130px !important;
              margin-bottom: 0 !important;
            }
          }
          &:nth-child(3) {
            grid-area: grid3;
            .serviceCard {
              height: 130px !important;
              margin-bottom: 0 !important;
            }
          }
          &:nth-child(4) {
            grid-area: grid4;
          }
          &:nth-child(5) {
            grid-area: grid5;
          }
          &:nth-child(6) {
            grid-area: grid6;
          }

          .partner-card-wrapper {
            cursor: pointer;
            height: 100%;

            .serviceCard {
              border-radius: 8px;
              overflow: hidden;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              position: relative;

              // Optimize hover for non-touch devices
              @media (hover: hover) {
                &:hover {
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              h5 {
                position: absolute;
                bottom: 16px;
                left: 16px;
                color: white;
                margin: 0;
                font-size: 18px;
                font-weight: 500;
                z-index: 2;

                @media (min-width: 768px) {
                  bottom: 20px;
                  left: 20px;
                  font-size: 20px;
                }
              }

              &::before {
                content: '';
                position: absolute;
                inset: 0;
                background: linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.7) 100%
                );
              }

              // Add perspective for 3D effect
              perspective: 1000px;
              transform-style: preserve-3d;

              // Container for the tilted content
              .card-tilt-container {
                position: relative;
                width: 100%;
                height: 100%;
                transform: rotateX(10deg) rotateY(-15deg);
                transition: transform 0.3s ease;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
              }

              // Hover effects for non-touch devices
              @media (hover: hover) {
                &:hover {
                  .card-tilt-container {
                    transform: rotateX(5deg) rotateY(-10deg);
                  }
                }
              }
            }

            h5 {
              transform: translateZ(20px);
              text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
            }

            &::before {
              transform: translateZ(10px);
            }
          }
        }
      }
    }
  }
}

// _partners.scss
.partner-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    background: white;
    width: 85%;
    height: 85vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    .modal-header {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 600;
        color: #333;
      }

      .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        padding: 0.5rem;
        cursor: pointer;
        color: #666;
        transition: all 0.2s;
        &:hover {
          color: #333;
          transform: scale(1.1);
        }
      }
    }

    .modal-body {
      flex: 1;
      position: relative;
      overflow: hidden;
      border-radius: 0 0 12px 12px;
    }
  }
}

// Partner cards styling
// .partner-card-wrapper {
//   cursor: pointer;
// }
.no-partners-container {
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
}

.no-partners-message {
  text-align: center;
  font-size: 0.875rem;
  color: #666;
  white-space: nowrap;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.partners-management {
  min-height: 100vh;
}

.iframe-loader {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
}

.loader-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.iframe-error {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.retry-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.hiddenIframe {
  display: none;
}

.loading-placeholder {
  height: 200px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.button-text {
  text-align: center;
  line-height: 1.2;

  .explore {
    display: block;
    font-size: 19px;
    font-weight: 700;
    color: #333;
  }

  .more {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }
}

.dot-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot-loader .dot {
  width: 8px;
  height: 8px;
  background-color: #000; /* Dot color */
  border-radius: 50%;
  margin: 0 4px; /* Space between dots */
  animation: bounce 1.4s infinite ease-in-out;
}

.dot-loader .dot:nth-child(2) {
  animation-delay: 0.2s; /* Delay for the second dot */
}

.dot-loader .dot:nth-child(3) {
  animation-delay: 0.4s; /* Delay for the third dot */
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: translateY(0); /* Dots at the bottom */
  }
  40% {
    transform: translateY(-10px); /* Dots bounce up */
  }
}
