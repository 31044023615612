@import "./fonts.scss";

$netto: "Netto";

// Used Color Variables

$themecolor: #ee183c;
$themefontColor: #ffffff;
@mixin gradientcolor {
    background-color: $themecolor;
}
@mixin shadow1 {
    box-shadow: 0px 3px 6px 0 #0000001a;
}
